import classes from './route.module.css';

import { Box, Button, Group } from '@mantine/core';
import type { LoaderFunctionArgs } from '@remix-run/node';
import { Outlet } from '@remix-run/react';
import cx from 'clsx';
import invariant from 'tiny-invariant';

import { LinkBuilder } from '@rockawayxlabs/observatory-utils';

import { Link, NavLink } from '~/features/links';
import { json, useLoaderData } from '~/features/serialization';
import { createRouteHandleWithBreadcrumb } from '~/utils/breadcrumbs';

export const handle = {
  routeMask: '/$zoneSlug/mev',
  ...createRouteHandleWithBreadcrumb((route) =>
  <Link key={route.pathname} to={route.pathname}>
      MEV
    </Link>
  )
};

export async function loader({ params }: LoaderFunctionArgs) {
  const { zoneSlug } = params;
  invariant(zoneSlug, 'zoneSlug param not found');

  return json({ zoneSlug });
}

export default function ZoneMevLayout() {
  const { zoneSlug } = useLoaderData<typeof loader>();
  const linkBuilder = LinkBuilder.zone(zoneSlug).mev();

  return (
    <>
      <Box className={classes.tabs} mb="lg">
        <Group justify="center" gap={0} className={classes.navLinkGroup}>
          <NavLink to={linkBuilder.toString()} prefetch="render" end className={classes.link}>
            {({ isActive }) =>
            <Button
              variant={isActive ? 'filled' : 'subtle'}
              fullWidth
              className={cx(classes.tab, {
                [classes.inactiveTab || '']: !isActive
              })}>

                Overview
              </Button>}

          </NavLink>
          <NavLink to={linkBuilder.blocks().toString()} className={classes.link}>
            {({ isActive }) =>
            <Button
              variant={isActive ? 'filled' : 'subtle'}
              fullWidth
              className={cx(classes.tab, {
                [classes.inactiveTab || '']: !isActive
              })}>

                Blocks
              </Button>}

          </NavLink>
          <NavLink to={linkBuilder.validators().toString()} className={classes.link}>
            {({ isActive }) =>
            <Button
              variant={isActive ? 'filled' : 'subtle'}
              fullWidth
              className={cx(classes.tab, {
                [classes.inactiveTab || '']: !isActive
              })}>

                Validators
              </Button>}

          </NavLink>
        </Group>
      </Box>
      <Outlet />
    </>);

}